.footer-box {
    background-color: #1976d2;
    color: #ffffff;
    padding: 32px 0;
  }
  
  .footer-title {
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .footer-text {
    margin-bottom: 8px;
  }
  
  .footer-divider {
    margin: 32px 0;
    background-color: rgba(255,255,255,0.5);
  }