html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: sans-serif;
}

.home-container {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    overflow: hidden; 
    display: flex;
    flex-direction: column;
}

.home-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;
    overflow: hidden;
}

/* Banner固定200px高度 */
.home-banner {
    background-image: url('https://cn.technave.com/wp-content/uploads/siriagwwee-1280x720.jpg');
    background-size: cover;
    background-position: center;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 32px;
    position: relative;
}

.home-banner-overlay {
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.home-banner-text {
    color: #ffffff;
    font-weight: bold;
    font-size: 2rem;
}

/* 标题、分割线 */
.home-divider {
    margin-bottom: 24px !important; 
}

.home-section-title {
    margin-bottom: 16px !important;
    font-weight: bold !important;
    font-size: 1.5rem;
}

/* 图片列表：2行×4列，卡片高度统一，比如350px */
.home-image-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 400px); 
    gap: 20px;
    overflow: hidden; 
    flex: 1;
    box-sizing: border-box;
}

/* 卡片 */
.home-shoe-item {
    border: 1px solid #eee;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0;
}

/* 图片区域：可尝试让图片区域占卡片高度的约60%~70%，例如70% */
.home-shoe-item .image-container {
    width: 100%;
    height: 70%; /* 增加图片展示区域，让其更宽敞 */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

/* 图片设置max-width和max-height，使其在容器中尽可能大但不裁剪 */
.home-shoe-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* 文本区域：剩余30%高度，用于显示标题、品牌、价格、评分 */
.home-shoe-info {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    text-align: center;
    overflow: hidden;
}

.home-shoe-title {
    font-weight: bold !important;
    font-size: 1rem;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%; 
}

.home-shoe-author {
    font-size: 0.9rem;
    color: #555;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    margin-bottom: 4px;
}

.home-shoe-price {
    font-weight: bold !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    margin-bottom: 4px;
}

.home-shoe-rating {
    font-size: 0.8rem;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

/* 分页区域 */
.home-pagination {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}