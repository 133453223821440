.gpt-container {
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  .gpt-container .MuiContainer-root {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  
  .gpt-container h5 {
    font-weight: bold;
  }
  
  .gpt-container .MuiDivider-root {
    margin: 32px 0 !important;
  }
  
  .gpt-container .MuiCard-root {
    padding: 16px;
  }
  
  .gpt-container ul {
    list-style: disc;
    margin-left: 20px;
    padding-left: 0;
  }
  
  .gpt-container ul li {
    margin-bottom: 8px;
  }
  
  .gpt-container .MuiImageList-root {
    overflow: hidden; 
  }
  
  .gpt-container .MuiImageListItem-root {
    transition: transform 0.2s;
  }
  
  .gpt-container .MuiImageListItem-root:hover {
    transform: scale(1.02);
  }