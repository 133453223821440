.header-appbar {
    background-color: #1976d2;
  }
  
  .header-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-title {
    font-weight: bold;
  }
  
  .header-link {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
  }
  
  .header-nav-item {
    margin-right: 20px;
    display: inline-block;
  }